import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";
import { FaYoutube } from "react-icons/fa";

const Overview = () => {
  return (
    <HowToLayout>
      <h2>Overview</h2>
      <section className="documentation-section">
        <h3 id="getting-started">Getting Started</h3>
        <p>
          As an Organizer, you'll want to decide which league plan is right for
          you. Check out our{" "}
          <Link className="inline-link" to="pricing">
            Pricing
          </Link>{" "}
          options figure out what meets your needs. We keep it simple and
          straight forward!
        </p>
        <p>
          For detailed app walkthroughs and How-To documentation, you're in the
          right place. Check out our{" "}
          <a
            href="https://www.youtube.com/channel/UC09hGJ_lAaF4uBqOONXnNKQ"
            target="_blank"
            className="inline-link"
          >
            <FaYoutube style={{ paddingTop: 5 }} /> YouTube channel
          </a>{" "}
          where you'll find a curated list of our video walkthroughs and
          explanations. These will also be listed throughout the How-To docs on
          this site.
        </p>
      </section>
      <section className="documentation-section">
        <h3 id="create-league">Create a League</h3>
        <p>
          After you've selected the right plan, whether it be a single league or
          unlimited, you'll want to{" "}
          <Link className="inline-link" to="create-league">
            create your league
          </Link>{" "}
          which can be found inside the in-app menu. Make sure you check the
          setting to allow registration so players can create their own teams!
        </p>
      </section>
      <section className="documentation-section">
        <h3 id="set-up-teams">Set up Teams</h3>
        <p>
          Teams can be created by you or by allowing the players to register
          their own (recommended for fewer organizer headaches). Learn more on
          managing{" "}
          <Link className="inline-link" to="teams-players">
            teams and players
          </Link>
          . Now would be a good time to make a Facebook post (or whichever way
          you manage your players) letting people know where/how to register.
        </p>
      </section>
      <section className="documentation-section">
        <h3 id="generate-schedule">Generate the Schedule</h3>
        <p>
          Now that all of your teams are registered and in place, you can{" "}
          <Link className="inline-link" to="schedule">
            generate your league schedule
          </Link>
          . The schedule can be generated when all of your teams are set. If you
          want to add teams later, you'll need to re-generate the schedule so
          that the round robin stays intact. You can generate the schedule at
          any time, we suggest generating this a week or so before hand so the
          players can plan on what time to show up and you can catch any small
          mistakes that you might have made in your league settings.
        </p>
      </section>
      <section className="documentation-section">
        <h3 id="ready-to-go">It's that Easy!</h3>
        <p>
          Now that your schedule has been generated, you're all set and ready
          for league to start! Your league players will now see the upcoming
          schedule matches in their upcoming dashboard and can get in some fun
          trash talk to their upcoming opponents.
        </p>
      </section>
    </HowToLayout>
  );
};

export default Overview;
